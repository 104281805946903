<template>
  <Matrix />
</template>

<script>
import Matrix from '@/components/Matrix/Matrix';
import documentService from '@/services/documentService';
import { mapGetters, mapActions, mapState } from 'vuex';
import { functions } from '@/utils/functions';

export default {
  name: 'DocumentPreview',
  data() {
    return {
      id_document: this.handleDecoderParam(this.$route.params.id_document),
      nm_document: this.$route.params.nm_document,
      id_user: this.handleDecoderParam(this.$route.params.id_user),
    };
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  mixins: [functions],
  created() {
    documentService
      .getOneDocument({ id_user: this.user.id, id_document: this.id_document })
      .then((res) => {
        if (res.message.length == 0) {
          let id_document = this.handleEncoderParam(this.id_document);
          let id_user = this.handleEncoderParam(this.user.id);

          router.push(
            `/doc/create/${this.nm_document}/${id_document}/${id_user}`
          );
        } else {
          res.message.document += `-preview`;

          this.$store.commit('document/setDocument', res.message);
        }
      })
      .catch((err) => {
        const url = document.URL;
        const statusError = err.response.status;
      });
  },
  methods: {
    ...mapActions('document', ['getOneDocument']),
  },
  components: {
    Matrix,
  },
};
</script>
