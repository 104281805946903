<template>
  <div>
    <div>
      <Matrix
        :doc="Number($route.params.id_plan)"
        :events="{ 'submit-form': this.handleCreateDocument }"
      />
    </div>
  </div>
</template>

<script>
import Matrix from '@/components/Matrix/Matrix';
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex';
import router from '@/router';
import { functions } from '@/utils/functions';
import {
  ID_ADVANCED_PLAN_EASY,
  ID_BASIC_PLAN_EASY,
  ID_COMPLETE_PLAN_EASY,
} from '@/utils/variables.json';

export default {
  name: 'DocumentCreate',
  mixins: [functions],
  data() {
    return {
      id_document: this.handleDecoderParam(this.$route.params.id_document),
      nm_document: this.$route.params.nm_document,
      id_plan: this.$route.params.id_plan,
      ID_ADVANCED_PLAN_EASY,
      ID_BASIC_PLAN_EASY,
      ID_COMPLETE_PLAN_EASY,
    };
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('document', ['document']),
  },
  methods: {
    ...mapActions('document', ['registerDocument', 'registerDocumentEasy']),
    ...mapActions('auth', ['registerEasy']),
    generateFormDocument() {
      const data = {
        document: `${this.nm_document}-form`,
        id_document: this.id_document,
        ds_json_props: JSON.stringify({
          'button-text':
            this.id_document === 1 ? 'Criar Documento' : 'Gerar documentos',
        }),
      };

      this.$store.commit('document/setDocument', data);
    },
    async handleCreateDocument(data) {
      delete data['button-text'];

      try {
        this.registerEasy({
          email: data.cd_email_usuario,
          document: data,
          id_document: this.id_document,
          callback: () => this.handleRedirect(data.cd_email_usuario),
        });
      } catch (err) {
        console.log(err);
      }
    },
    handleRedirect(customerEmail) {
      switch (Number(this.id_plan)) {
        case this.ID_BASIC_PLAN_EASY:
          this.$router.push('/app');
          return;
        case this.ID_COMPLETE_PLAN_EASY:
          this.handleNavigateCheckout(router, {
            plan_id: this.ID_COMPLETE_PLAN_EASY,
            customer_email: customerEmail,
          });
          return;
        case this.ID_ADVANCED_PLAN_EASY:
          this.handleNavigateCheckout(router, {
            plan_id: this.ID_ADVANCED_PLAN_EASY,
            customer_email: customerEmail,
          });
          return;
      }
    },
  },
  created() {
    this.generateFormDocument();
  },
  beforeDestroy() {
    this.$store.commit('document/clearDocument');
  },
  components: {
    Matrix,
  },
};
</script>
