<template>
  <div class="mt-3 relative">
    <content-placeholders
      class="container mx-auto doc"
      v-if="document.length > 0"
    >
      <content-placeholders-text :lines="1" class="mt-3" />
    </content-placeholders>
    <VueHtml2pdf
      v-else
      :show-layout="controlValue.showLayout"
      :float-layout="controlValue.floatLayout"
      :enable-download="controlValue.enableDownload"
      :preview-modal="controlValue.previewModal"
      :filename="controlValue.filename"
      :paginate-elements-by-height="controlValue.paginateElementsByHeight"
      :pdf-quality="controlValue.pdfQuality"
      :pdf-format="controlValue.pdfFormat"
      :pdf-orientation="controlValue.pdfOrientation"
      :pdf-content-width="controlValue.pdfContentWidth"
      :manual-pagination="controlValue.manualPagination"
      :html-to-pdf-options="htmlToPdfOptions"
      @hasDownloaded="navigateToDashboard"
      ref="html2Pdf"
      class=""
    >
      <Matrix slot="pdf-content" :settings="settings" />
    </VueHtml2pdf>

    <DocumentEditor :settings="settings" />
  </div>
</template>

<script>
import Matrix from '@/components/Matrix/Matrix';
import DocumentEditor from '@/components/DocumentEditor/DocumentEditor.vue';

import documentService from '@/services/documentService';
import { mapActions, mapState } from 'vuex';
import VueHtml2pdf from 'vue-html2pdf';

import { functions } from '@/utils/functions';

import router from '@/router';

export default {
  name: 'DocumentOverview',
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('document', ['selectedDocument', 'document']),
    htmlToPdfOptions() {
      return {
        margin: [0, 1, 0, 1],
        filename: this.pdfDownloadName,
        image: {
          type: 'jpeg',
          quality: 0.98,
        },
        enableLinks: true,
        html2canvas: {
          scale: this.controlValue.pdfQuality,
          useCORS: true,
        },
        jsPDF: {
          unit: 'in',
          format: this.controlValue.pdfFormat,
          orientation: this.controlValue.pdfOrientation,
        },
        pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
      };
    },
    pdfDownloadName() {
      let documentName = this.serializedNameDocument(this.nm_document);

      return `${documentName}.pdf`;
    },
  },
  mixins: [functions],
  beforeRouteEnter(to, from, next) {
    const pathsToRedirectHome = ['Checkout', 'documentPreview'];

    if (pathsToRedirectHome.includes(from.name)) {
      router.push('/app');
    } else {
      next();
    }
  },
  data() {
    return {
      showButtonDownload: false,
      id_document: this.handleDecoderParam(this.$route.params.id_document),
      nm_document: this.$route.params.nm_document,
      id_user: this.handleDecoderParam(this.$route.params.id_user),

      controlValue: {
        showLayout: true,
        floatLayout: false,
        enableDownload: true,
        previewModal: true,
        paginateElementsByHeight: 8000,
        manualPagination: false,
        pdfQuality: 2,
        pdfFormat: 'a4',
        pdfOrientation: 'portrait',
        pdfContentWidth: '760px',
      },

      settings: {
        settingsHeading: {
          color: '#333',
          focused_font: 'Arial',
          focused_size: '18px',
          available_size: [
            '12px',
            '13px',
            '14px',
            '18px',
            '19px',
            '20px',
            '24px',
            '26px',
            '28px',
            '30px',
            '36px',
            '48px',
            '64px',
          ],
          available_fonts: [
            'Arial',
            'Pacifico',
            'Helvetica',
            'Times New Roman',
            'Times',
            'Courier',
            'Shadows Into Light',
            'Lobster',
            'Anton',
            'Indie Flower',
            'Comic Sans MS',
            'Georgia',
            'Palatino',
            'Mali',
            'Garamond',
            'Arial Black',
            'Impact',
            'Srisakdi',
            'Slabo 27px',
          ],
        },
        settingsBody: {
          color: '#333',
          focused_font: 'Arial',
          focused_size: '14px',
          available_size: [
            '12px',
            '13px',
            '14px',
            '18px',
            '19px',
            '20px',
            '24px',
            '26px',
            '28px',
            '30px',
            '36px',
            '48px',
            '64px',
          ],
          available_fonts: [
            'Arial',
            'Pacifico',
            'Helvetica',
            'Times New Roman',
            'Times',
            'Courier',
            'Shadows Into Light',
            'Lobster',
            'Anton',
            'Indie Flower',
            'Comic Sans MS',
            'Georgia',
            'Palatino',
            'Mali',
            'Garamond',
            'Arial Black',
            'Impact',
            'Srisakdi',
            'Slabo 27px',
          ],
        },
        settingsLink: {
          color: '#333',
          focused_font: 'Arial',
          focused_size: '14px',
          available_size: [
            '12px',
            '13px',
            '14px',
            '18px',
            '19px',
            '20px',
            '24px',
            '26px',
            '28px',
            '30px',
            '36px',
            '48px',
            '64px',
          ],
          available_fonts: [
            'Arial',
            'Pacifico',
            'Helvetica',
            'Times New Roman',
            'Times',
            'Courier',
            'Shadows Into Light',
            'Lobster',
            'Anton',
            'Indie Flower',
            'Comic Sans MS',
            'Georgia',
            'Palatino',
            'Mali',
            'Garamond',
            'Arial Black',
            'Impact',
            'Srisakdi',
            'Slabo 27px',
          ],
        },
        settingsExtra: {
          backgroundColor: '#fff',
          showPowered: true,
        },
      },
    };
  },
  methods: {
    ...mapActions('document', ['getOneDocument']),
    ...mapActions('navbar', ['toggleNavbar']),
    ...mapActions('footer', ['toggleFooter']),
    ...mapActions('alert', ['success', 'error']),

    async downloadPdf({ showAlert = true }) {
      if (showAlert) {
        this.success({
          showType: 'toast',
          text: 'Realizando download do documento.',
        });
      }

      if (!(await this.validateControlValue())) return;

      this.$refs.html2Pdf.generatePdf();
    },

    navigateToDashboard() {
      router.push('/app');
    },

    validateControlValue() {
      if (this.controlValue.pdfQuality > 2) {
        alert('pdf-quality value should only be 0 - 2');
        this.controlValue.pdfQuality = 2;

        return false;
      }

      if (!this.controlValue.paginateElementsByHeight) {
        alert('paginate-elements-by-height value cannot be empty');
        this.controlValue.paginateElementsByHeight = 1400;

        return false;
      }

      const paperSizes = [
        'a0',
        'a1',
        'a2',
        'a3',
        'a4',
        'letter',
        'legal',
        'a5',
        'a6',
        'a7',
        'a8',
        'a9',
        'a10',
      ];

      if (!paperSizes.includes(this.controlValue.pdfFormat)) {
        alert(`pdf-format value should only be ${paperSizes}`);
        this.controlValue.pdfFormat = 'a4';

        return false;
      }

      if (!this.controlValue.pdfOrientation) {
        alert('pdf-orientation value cannot be empty');
        this.controlValue.pdfOrientation = 'portrait';

        return false;
      }

      if (!this.controlValue.pdfContentWidth) {
        alert('pdf-content-width value cannot be empty');
        this.controlValue.pdfContentWidth = '800px';

        return false;
      }

      return true;
    },
  },
  watch: {
    document: {
      handler: function () {
        // Se for passado o parametro de donwload
        if (this.$route.params.download) {
          // Espera 1 segundo para carregar o documento
          setTimeout(() => {
            // Verifica se tem acesso para donwload
            if (this.document.hasAccess) {
              this.success({
                showType: 'toast',
                text: 'Realizando download do documento.',
              });

              this.downloadPdf({ showAlert: false });
            } else {
              this.error({
                showType: 'toast',
                text:
                  'Para realizar o download do documento você precisa realizar a compra.',
              });
            }
          }, 1000);
        }
      },
      deep: true,
    },
  },
  created() {
    documentService
      .getOneDocument({ id_user: this.user.id, id_document: this.id_document })
      .then((res) => {
        if (!res.message.ds_json_props) {
          let id_document = this.handleEncoderParam(this.id_document);
          let id_user = this.handleEncoderParam(this.user.id);

          router.push(
            `/doc/create/${this.nm_document}/${id_document}/${id_user}`
          );
        } else {
          if (res.message.hasAccess) {
            this.$store.commit('document/setDocument', res.message);
          } else {
            res.message.document += `-preview`;

            this.$store.commit('document/setDocument', res.message);

            let id_document = this.handleEncoderParam(this.id_document);
            let id_user = this.handleEncoderParam(this.user.id);

            router.push(
              `/doc/preview/${this.nm_document}/${id_document}/${id_user}`
            );
          }
        }
      })
      .catch((err) => {
        const url = document.URL;
        const statusError = err.response.status;
      });

    documentService
      .getStylesFromDocument({
        id_user: this.user.id,
        id_document: this.id_document,
      })
      .then((res) => {
        if (res.message.ds_json_styles) {
          this.settings = JSON.parse(res.message.ds_json_styles);

          this.$forceUpdate();
        }
      });

    if (this.$route.query?.h_hud) {
      this.toggleNavbar({ navbarInvisible: true });
    }

    if (this.$route.query?.h_hud) {
      this.toggleFooter({ footerInvisible: true });
    }
  },
  components: {
    Matrix,
    VueHtml2pdf,
    DocumentEditor,
  },
};
</script>

<style lang="scss">
.content-wrapper {
  width: 100% !important;
}

.doc .vue-content-placeholders-text__line {
  width: 100% !important;
  height: 100vh;
  margin-top: 50px;
  border-radius: 10px;
  background: #f7f7f7;
  margin-bottom: 30px !important;

  .vue-content-placeholders-text__line::before {
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(transparent),
      color-stop(15%, #f1f1f1),
      color-stop(30%, transparent)
    );
    background: linear-gradient(
      to right,
      transparent 0%,
      #f1f1f1 15%,
      transparent 30%
    );
  }
}
</style>
