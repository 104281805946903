<template>
  <div>
    <div v-if="!loading">
      <template v-if="error">
        <div
          class="h-screen w-screen bg-indigo-600 flex justify-center content-center flex-wrap"
        >
          <h1 class="text-white text-center w-3/6" style="font-size: 2rem">
            Documento não encontrado, entre em contato com o administrador do
            site
          </h1>
        </div>
      </template>
      <Matrix v-else :settings="settings" />
    </div>
  </div>
</template>

<script>
import Matrix from '@/components/Matrix/Matrix';
import documentService from '@/services/documentService';
import { mapGetters, mapActions, mapState } from 'vuex';
import stripeService from '@/services/stripeService';
import { functions } from '@/utils/functions';

import axios from 'axios';
import configService from '@/services/configService';
import { loadStripe } from '@stripe/stripe-js';

import router from '@/router';

export default {
  name: 'DocumentEmebed',
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('document', ['selectedDocument', 'document']),
  },
  mixins: [functions],
  data() {
    return {
      id_document: this.handleDecoderParam(this.$route.params.id_document),
      nm_document: this.$route.params.nm_document,
      id_user: this.handleDecoderParam(this.$route.params.id_user),
      error: false,
      loading: true,
      settings: {
        settingsHeading: {
          color: '#333',
          focused_font: 'Arial',
          focused_size: '32px',
          available_size: [
            '12px',
            '13px',
            '14px',
            '18px',
            '19px',
            '20px',
            '24px',
            '26px',
            '28px',
            '30px',
            '36px',
            '48px',
            '64px',
          ],
          available_fonts: [
            'Arial',
            'Pacifico',
            'Helvetica',
            'Times New Roman',
            'Times',
            'Courier',
            'Shadows Into Light',
            'Lobster',
            'Anton',
            'Indie Flower',
            'Comic Sans MS',
            'Georgia',
            'Palatino',
            'Mali',
            'Garamond',
            'Arial Black',
            'Impact',
            'Srisakdi',
            'Slabo 27px',
          ],
        },
        settingsBody: {
          color: '#333',
          focused_font: 'Arial',
          focused_size: '14px',
          available_size: [
            '12px',
            '13px',
            '14px',
            '18px',
            '19px',
            '20px',
            '24px',
            '26px',
            '28px',
            '30px',
            '36px',
            '48px',
            '64px',
          ],
          available_fonts: [
            'Arial',
            'Pacifico',
            'Helvetica',
            'Times New Roman',
            'Times',
            'Courier',
            'Shadows Into Light',
            'Lobster',
            'Anton',
            'Indie Flower',
            'Comic Sans MS',
            'Georgia',
            'Palatino',
            'Mali',
            'Garamond',
            'Arial Black',
            'Impact',
            'Srisakdi',
            'Slabo 27px',
          ],
        },
        settingsLink: {
          color: '#333',
          focused_font: 'Arial',
          focused_size: '14px',
          available_size: [
            '12px',
            '13px',
            '14px',
            '18px',
            '19px',
            '20px',
            '24px',
            '26px',
            '28px',
            '30px',
            '36px',
            '48px',
            '64px',
          ],
          available_fonts: [
            'Arial',
            'Pacifico',
            'Helvetica',
            'Times New Roman',
            'Times',
            'Courier',
            'Shadows Into Light',
            'Lobster',
            'Anton',
            'Indie Flower',
            'Comic Sans MS',
            'Georgia',
            'Palatino',
            'Mali',
            'Garamond',
            'Arial Black',
            'Impact',
            'Srisakdi',
            'Slabo 27px',
          ],
        },
        settingsExtra: {
          backgroundColor: '#fff',
          showPowered: true,
        },
      },
    };
  },
  methods: {
    ...mapActions('document', ['getOneDocument']),
    ...mapActions('navbar', ['toggleNavbar']),
    ...mapActions('footer', ['toggleFooter']),
  },
  created() {
    this.toggleNavbar({ navbarInvisible: true });
    this.toggleFooter({ footerInvisible: true });

    documentService
      .getOneDocument({ id_user: this.id_user, id_document: this.id_document })
      .then((res) => {
        if (res.message.length == 0 || !res.message.hasAccess) {
          this.error = true;
        } else {
          this.$store.commit('document/setDocument', res.message);
        }

        this.loading = false;
      })
      .catch((err) => {
        const url = document.URL;
        const statusError = err.response.status;

        this.error = true;
        this.loading = false;
      });

    documentService
    .getStylesFromDocument({
      id_user: this.user.id,
      id_document: this.id_document,
    })
    .then((res) => {
      if (res.message.ds_json_styles) {
        this.settings = JSON.parse(res.message.ds_json_styles);

        this.$forceUpdate();
      }
    });
  },
  components: {
    Matrix,
  },
};
</script>
