<template>
  <div>
    <div>
      <Matrix :events="{ 'submit-form': this.handleUpdateDocument }" />
    </div>
  </div>
</template>

<script>
import Matrix from '@/components/Matrix/Matrix';
import { mapGetters, mapActions, mapState } from 'vuex';
import documentService from '@/services/documentService';
import router from '@/router';
import { functions } from '@/utils/functions';

export default {
  name: 'DocumentUpdate',
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('document', ['selectedDocument', 'document']),
  },
  mixins: [functions],
  data() {
    return {
      id_document: this.handleDecoderParam(this.$route.params.id_document),
      nm_document: this.$route.params.nm_document,
      id_user: this.handleDecoderParam(this.$route.params.id_user),
    };
  },
  methods: {
    ...mapActions('document', ['updateDocument']),
    handleUpdateDocument(data) {
      delete data['button-text'];
      this.updateDocument({
        id_user: Number(this.user.id),
        id_document: Number(this.id_document),
        document: data,
        router,
      });
    },
  },
  created() {
    documentService
      .getOneDocument({ id_user: this.user.id, id_document: this.id_document })
      .then((res) => {
        if (!res.message.ds_json_props) {
          let id_document = this.handleEncoderParam(this.id_document);
          let id_user = this.handleEncoderParam(this.user.id);

          router.push(
            `/doc/create/${this.nm_document}/${id_document}/${id_user}`
          );
        } else {
          res.message.is_updating = true;

          this.$store.commit('document/setDocument', res.message);
        }
      })
      .catch((err) => {
        router.push('/app');
        console.log(err);
      });
  },
  components: {
    Matrix,
  },
};
</script>
