<template>
  <div>
    <div>
      <Matrix :events="{ 'submit-form': this.handleCreateDocument }"/>
    </div>
  </div>
</template>

<script>
import Matrix from '@/components/Matrix/Matrix';
import {mapGetters, mapActions, mapState, mapMutations} from 'vuex';
import router from '@/router';
import {functions} from '@/utils/functions';
import {
  ID_ADVANCED_PLAN_EASY,
  ID_BASIC_PLAN_EASY,
  ID_COMPLETE_PLAN_EASY,
  ID_POLITICAS_COOKIES,
  ID_SPECIAL_PLAN_OLD_USERS
} from '@/utils/variables.json';
import userService from "@/services/userService";

export default {
  name: 'DocumentCreate',
  mixins: [functions],
  data() {
    return {
      id_document: this.handleDecoderParam(this.$route.params.id_document),
      nm_document: this.$route.params.nm_document,
      id_plan: this.$route.params.id_plan,
      ID_ADVANCED_PLAN_EASY,
      ID_BASIC_PLAN_EASY,
      ID_COMPLETE_PLAN_EASY,
      ID_POLITICAS_COOKIES,
      ID_SPECIAL_PLAN_OLD_USERS,
      showAlert: false,
      actualPlan: {
        has_plan: false,
        plans: []
      },
    };
  },
  provide() {
    return {
      showAlert: this.showAlert
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('document', ['document']),
    createPoliticaCookie() {
      const allowedPlans = [this.ID_COMPLETE_PLAN_EASY, this.ID_ADVANCED_PLAN_EASY, this.ID_SPECIAL_PLAN_OLD_USERS]
      return this.actualPlan.has_plan && (this.actualPlan.plans.includes(allowedPlans[0]) || this.actualPlan.plans.includes(allowedPlans[1]) || this.actualPlan.plans.includes(allowedPlans[2]));
    }
  },
  methods: {
    ...mapActions('document', ['registerDocument', 'registerDocumentEasy']),
    ...mapActions('auth', ['registerEasy']),
    generateFormDocument() {
      const data = {
        document: `${this.nm_document}-form`,
        id_document: this.id_document,
        ds_json_props: JSON.stringify({
          'button-text': 'Criar Documento',
        }),
      };

      this.$store.commit('document/setDocument', data);
    },
    async handleCreateDocument(data) {
      delete data['button-text'];

      try {
        if (this.nm_document === "politicas-privacidade") {
          if (this.createPoliticaCookie) {

            let dataPC = {
              is_utilizado_cookie: data.is_utilizado_cookie,
              is_utilizado_cookie_auth: data.is_utilizado_cookie_auth,
              is_utilizado_cookie_desempenho: data.is_utilizado_cookie_desempenho,
              is_utilizado_cookie_marketing: data.is_utilizado_cookie_marketing,
              is_utilizado_cookie_necessarios: data.is_utilizado_cookie_necessarios,
              is_utilizado_cookie_rede_sociais: data.is_utilizado_cookie_rede_sociais,
            }

            this.registerDocument({
              document: dataPC,
              id_document: this.ID_POLITICAS_COOKIES,
              user: this.user,
              router,
              showAlert: false,
              redirect: false
            })
          }
        }


        this.registerDocument({
          document: data,
          id_document: this.id_document,
          user: this.user,
          router,
        });
      } catch (err) {
        console.log(err);
        this.showAlert = false;
      }
    },
    handleRedirect() {
      switch (Number(this.id_plan)) {
        case this.ID_BASIC_PLAN_EASY:
          this.$router.push('/app');
          return;
        case this.ID_COMPLETE_PLAN_EASY:
          this.handleNavigateCheckout(router, {
            plan_id: this.ID_COMPLETE_PLAN_EASY,
          });
          return;
        case this.ID_ADVANCED_PLAN_EASY:
          this.handleNavigateCheckout(router, {
            plan_id: this.ID_ADVANCED_PLAN_EASY,
          });
          return;
      }
    },
    getActualPlanUser() {
      userService.actualPlan().then(res => {
        if (res && res.data) {
          this.actualPlan = res.data;
        }
      })
    }
  },
  created() {
    this.getActualPlanUser();
    this.generateFormDocument();
  },
  beforeDestroy() {
    this.$store.commit('document/clearDocument');
  },
  components: {
    Matrix,
  },
};
</script>
