<template>
  <div>
    <div>
      <section class="text-gray-700 body-font overflow-hidden">
        <section class="container mx-auto pt-24 pb-12 px-4">
          <div class="w-full mx-auto text-left md:text-center">
            <h1
              class="text-4xl md:text-4xl leading-none mb-5 font-extrabold tracking-normal md:tracking-tight bg-clip-text text-transparent bg-gradient-to-r from-indigo-700 to-indigo-600 block pb-2"
            >
              Preço simples e transparente.
            </h1>
            <p class="text-lg md:text-xl text-gray-600 mb-8 px-0 lg:px-24">
              Valores que funcionam para empresas de qualquer tamanho.
            </p>
          </div>
          <div class="w-fullmx-auto">
            <div class="grid grid-cols-1 lg:grid-cols-3 gap-16 sm:gap-8">
              <div
                class="card rounded-none shadow-none sm:rounded-lg border-0 md:border"
              >
                <div
                  class="flex flex-col p-6 justify-between border-b border-gray-200"
                >
                  <p class="text-indigo-700 font-semibold text-lg mb-1">
                    LGPD Completo
                  </p>
                  <p
                    class="text-gray-900 font-extrabold text-4xl font-mono pb-0"
                  >
                    R$39.90
                  </p>
                  <p class="text-gray-600 text-sm">Empresa / mês</p>
                  <button
                    class="btn btn-primary bg-indigo-600 btn-lg w-full mt-6"
                    @click="() => handleSelectPlan(1)"
                  >
                    Comece agora mesmo &rarr;
                  </button>
                </div>
                <ul class="flex flex-col flex-grow p-6 space-y-3">
                  <li class="flex items-start">
                    <svg
                      class="w-4 h-4 mr-2 mt-1 flex-none text-indigo-700"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        clip-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        fill-rule="evenodd"
                      ></path>
                    </svg>
                    <span class="text-gray-800"> Política de privacidade </span>
                  </li>
                  <li class="flex items-start">
                    <svg
                      class="w-4 h-4 mr-2 mt-1 flex-none text-indigo-700"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        clip-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        fill-rule="evenodd"
                      ></path>
                    </svg>
                    <span class="text-gray-800"> Termos de uso </span>
                  </li>
                  <li class="flex items-start">
                    <svg
                      class="w-4 h-4 mr-2 mt-1 flex-none text-indigo-700"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        clip-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        fill-rule="evenodd"
                      ></path>
                    </svg>
                    <span class="text-gray-800">
                      Edição visual de documentos
                    </span>
                  </li>
                  <li class="flex items-start">
                    <svg
                      class="w-4 h-4 mr-2 mt-1 flex-none text-indigo-700"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        clip-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        fill-rule="evenodd"
                      ></path>
                    </svg>
                    <span class="text-gray-800"> Relatório de impacto </span>
                  </li>
                  <li class="flex items-start">
                    <svg
                      class="w-4 h-4 mr-2 mt-1 flex-none text-indigo-700"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        clip-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        fill-rule="evenodd"
                      ></path>
                    </svg>
                    <span class="text-gray-800">
                      Planilha de registro e operações
                    </span>
                  </li>
                  <li class="flex items-start">
                    <svg
                      class="w-4 h-4 mr-2 mt-1 flex-none text-indigo-700"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        clip-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        fill-rule="evenodd"
                      ></path>
                    </svg>
                    <span class="text-gray-800">
                      Guia de utilização das planilhas
                    </span>
                  </li>
                </ul>
              </div>
              <div
                class="card rounded-none shadow-none sm:rounded-lg border-0 md:border"
              >
                <div
                  class="flex flex-col p-6 justify-between border-b border-gray-200"
                >
                  <div>
                    <div class="flex justify-between items-center">
                      <p class="text-indigo-700 font-semibold text-lg mb-1">
                        LGPD & Gestão
                      </p>
                    </div>
                    <p class="text-gray-900 font-extrabold text-4xl font-mono">
                      R$49.90
                    </p>
                    <p class="text-gray-600 text-sm">Empresa / mês</p>
                  </div>
                  <button
                    class="btn bg-indigo-700 text-white btn-lg w-full mt-6"
                    disabled
                    @click="() => handleSelectPlan(2)"
                  >
                    A partir de Julho/2021 &rarr;
                  </button>
                </div>
                <ul class="flex flex-col flex-grow p-6 space-y-3">
                  <li class="flex items-start">
                    <svg
                      class="w-4 h-4 mr-2 mt-1 flex-none text-purple-700"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        clip-rule="evenodd"
                        d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                        fill-rule="evenodd"
                      />
                    </svg>
                    <span class="text-purple-700 font-medium"
                      >Tudo do plano completo</span
                    >
                  </li>

                  <li class="flex items-start">
                    <svg
                      class="w-4 h-4 mr-2 mt-1 flex-none text-purple-700"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        clip-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        fill-rule="evenodd"
                      ></path>
                    </svg>
                    Templates visuais
                  </li>

                  <li class="flex items-start">
                    <svg
                      class="w-4 h-4 mr-2 mt-1 flex-none text-purple-700"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        clip-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        fill-rule="evenodd"
                      ></path>
                    </svg>

                    <span>Ebook Relátorio de impacto e proteção de dados</span>
                  </li>
                  <li class="flex items-start">
                    <svg
                      class="w-4 h-4 mr-2 mt-1 flex-none text-purple-700"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        clip-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        fill-rule="evenodd"
                      ></path>
                    </svg>

                    <span>
                      Ebook Registro de opções de tratamento de dados pessoais
                    </span>
                  </li>
                  <li class="flex items-start">
                    <svg
                      class="w-4 h-4 mr-2 mt-1 flex-none text-purple-700"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        clip-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        fill-rule="evenodd"
                      ></path>
                    </svg>

                    <span>
                      Ebook Guia de utilização da planilha de registro de
                      operações
                    </span>
                  </li>
                  <li
                    v-for="item in nextFeatures"
                    :key="item"
                    class="flex items-start"
                  >
                    <svg
                      class="w-4 h-4 mr-2 mt-1 flex-none text-purple-700"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        clip-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        fill-rule="evenodd"
                      ></path>
                    </svg>

                    <span>
                      {{ item }}
                    </span>
                  </li>
                  <li class="flex items-start">
                    <svg
                      class="w-4 h-4 mr-2 mt-1 flex-none text-purple-700"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        clip-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        fill-rule="evenodd"
                      ></path>
                    </svg>
                    <span>Suporte prioritário.</span>
                  </li>
                </ul>
              </div>
              <div
                class="card rounded-none shadow-none sm:rounded-lg border-0 md:border"
              >
                <div
                  class="flex flex-col p-6 justify-between border-b border-gray-200"
                >
                  <div>
                    <p class="text-pink-600 font-semibold text-lg mb-1">
                      Business
                    </p>
                    <p class="text-gray-900 font-bold text-4xl">
                      Personalizado
                    </p>
                    <p class="text-gray-600 text-sm">Empresa / mês</p>
                  </div>
                  <router-link
                    to="/contato"
                    class="btn btn-light btn-lg w-full mt-6"
                    >Entrar em contato &rarr;</router-link
                  >
                </div>
                <ul class="flex flex-col flex-grow p-6 space-y-3">
                  <li class="flex items-start">
                    <svg
                      class="w-4 h-4 mr-2 mt-1 flex-none text-pink-600"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        clip-rule="evenodd"
                        d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                        fill-rule="evenodd"
                      />
                    </svg>
                    <span class="text-pink-600 font-medium"
                      >Tudo no plano LGPD Completo</span
                    >
                  </li>
                  <li class="flex items-start">
                    <svg
                      class="w-4 h-4 mr-2 mt-1 flex-none text-pink-700"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        clip-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        fill-rule="evenodd"
                      ></path>
                    </svg>

                    Criação de templates whitelabel
                  </li>
                  <li class="flex items-start">
                    <svg
                      class="w-4 h-4 mr-2 mt-1 flex-none text-pink-700"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        clip-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        fill-rule="evenodd"
                      ></path>
                    </svg>

                    Coloque sua marca nos termos
                  </li>
                  <li class="flex items-start">
                    <svg
                      class="w-4 h-4 mr-2 mt-1 flex-none text-pink-700"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        clip-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        fill-rule="evenodd"
                      ></path>
                    </svg>

                    Suporte prioritário
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </section>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import router from '@/router';
import stripeService from '@/services/stripeService';

import axios from 'axios';
import configService from '@/services/configService';
import { loadStripe } from '@stripe/stripe-js';

export default {
  name: 'PreCheckout',
  computed: {},
  data() {
    return {
      nextFeatures: [
        'Ebook para construção de politica de privacidade interna para colaboradores',
        'Gerador de Politicas de Cookies e Templates Visuais',
        'Acordo de Confidencialidade',
        'Contrato de Desenvolvimento de Software',
      ],
    };
  },
  methods: {
    handleSelectPlan(planId) {
      stripeService.redirectToCheckoutEasy(planId);
    },
  },
  components: {},
};
</script>
